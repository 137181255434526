<template>
	<errorContainer :error="erreur">
		<transition name="slide">
			<div
				class="container-layout details-document-package"
				:class="{
					'container-layout': $screen.width > 576,
					'container-mobile': $screen.width <= 576
				}"
			>
				<div class="form">
					<b-row>
						<b-col md="12">
							<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">
								{{ FormMSG(17, 'Document package detail') }}
							</h1>
						</b-col>
					</b-row>
					<b-row class="mt-3">
						<b-col class="p-0">
							<b-card no-body>
								<b-row class="back-with-title cursor-pointer py-1" :class="{ 'mt-16': $isPwa() }">
									<b-col cols="6">
										<button class="btn-transparent" @click="handleClickCancel">
											<arrow-left color="rgba(6, 38, 62, 0.65)" :size="22" class="icon" />
											<span style="display: inline-block; color: #2e495d; font-weight: bolder; font-size: 16px">
												{{ FormMSG(163, 'Back') }}
											</span>
										</button>
									</b-col>
								</b-row>

								<b-card-text>
									<div class="container-field mt-3">
										<b-row v-if="showCreateNewVersion">
											<b-col>
												<b-alert variant="success" show>
													<b-row>
														<b-col cols="9">
															{{
																FormMSG(
																	145,
																	'You can no longer make a modification on this package. You must create another version.'
																)
															}}
														</b-col>
														<b-col cols="3">
															<b-button variant="success" block size="sm" @click="handleClickCreateNewVersion">
																<PlusCircle :size="16" />
																{{ FormMSG(146, 'Create new version') }}
															</b-button>
														</b-col>
													</b-row>
												</b-alert>
											</b-col>
										</b-row>
										<b-row v-if="showHasNextVersion">
											<b-col>
												<b-alert variant="warning" show>
													<b-row>
														<b-col>
															{{ FormMSG(196, 'This document already has a version created,') }}
															<router-link
																:to="`/document-package/${documentData.documentNextVersionId}`"
																class="text-color-aqua-velvet"
																>{{ FormMSG(197, 'click here to see it.') }}</router-link
															>
														</b-col>
													</b-row>
												</b-alert>
											</b-col>
										</b-row>
										<b-row>
											<b-col>
												<h2>{{ FormMSG(18, 'Settings') }}</h2>
											</b-col>
										</b-row>
										<b-row>
											<b-col cols="3">
												<b-form-group :label="FormMSG(111, 'Version')">
													<b-form-input :value="`V${documentData.version}`" disabled />
												</b-form-group>
											</b-col>
										</b-row>
										<b-row>
											<b-col sm="12" md="6" lg="6" xl="6">
												<b-form-group :label="FormMSG(112, 'Name')">
													<b-form-input
														v-model="documentData.name"
														:placeholder="FormMSG(19, 'Ex: Technician onboarding package')"
														:disabled="isFrozen"
													/>
												</b-form-group>
											</b-col>
											<b-col sm="12" md="6" lg="6" xl="6">
												<b-form-group :label="FormMSG(20, 'Sharing options')">
													<div id="chk_details_doc_package">
														<b-form-checkbox id="sendEmail" v-model="documentData.toEmail" :disabled="isFrozen">
															{{ FormMSG(21, 'Send by email') }}
														</b-form-checkbox>
														<b-form-checkbox id="sendSMS" v-model="documentData.toSms" :disabled="isFrozen">
															{{ FormMSG(22, 'Send by SMS') }}
														</b-form-checkbox>
														<b-form-checkbox id="sendWatsApp" v-model="documentData.toWhatsApp" :disabled="isFrozen">
															{{ FormMSG(23, 'Send by WhatsApp') }}
														</b-form-checkbox>
													</div>
												</b-form-group>
											</b-col>
										</b-row>
										<b-row>
											<b-col sm="12" md="3" lg="3" xl="3">
												<b-form-group :label="FormMSG(51, 'Created by')">
													<b-form-input :value="`${documentData.publisher.name} ${documentData.publisher.firstName}`" disabled />
												</b-form-group>
											</b-col>
											<b-col sm="12" md="3" lg="3" xl="3">
												<b-form-group :label="FormMSG(53, 'Creation date')">
													<b-form-input :value="getDateFormatted(documentData.createdAt)" disabled />
												</b-form-group>
											</b-col>
											<b-col sm="12" md="6" lg="6" xl="6">
												<b-form-group :label="FormMSG(65, 'Message')">
													<b-form-textarea
														id="textarea"
														v-model="documentData.body"
														:placeholder="FormMSG(24, 'Enter message here...')"
														no-resize
														:state="documentData.body && documentData.body.length < 4000"
														rows="3"
														:disabled="isFrozen"
													/>
												</b-form-group>
											</b-col>
										</b-row>
										<div class="divider-line mt-3" />
										<b-row class="mt-20 mb-20">
											<b-col>
												<h2>{{ FormMSG(55, 'Approval steps') }}</h2>
											</b-col>
										</b-row>
										<b-row>
											<b-col>
												<b-form-checkbox
													size="lg"
													id="requireSignManager"
													v-model="documentData.requireManagerApproval"
													:disabled="isFrozen"
													class="pj-cb pb-1"
												>
													{{ FormMSG(169, 'Require approval / manager signature') }}
												</b-form-checkbox>
											</b-col>
										</b-row>
										<b-collapse :visible="documentData.requireManagerApproval">
											<choice-step-document
												:is-disabled="isFrozen"
												:document="documentData"
												@choice-step-document:change="handleChoiceStepDocumentChange"
											/>
										</b-collapse>
										<div v-if="!isFrozen" class="mt-5">
											<b-row v-if="$screen.width > 576" class="mt-25">
												<b-col cols="4" offset="8">
													<b-button variant="primary" block :disabled="isLoading" @click="handleClickSave">
														{{ FormMSG(27, 'Save changes') }}
													</b-button>
												</b-col>
											</b-row>
											<b-row v-if="$screen.width <= 576" class="footer-fixed">
												<b-col cols="12" class="mb-3">
													<b-button variant="primary" block :disabled="isLoading" @click="handleClickSave">
														{{ FormMSG(27, 'Save changes') }}
													</b-button>
												</b-col>
											</b-row>
										</div>
										<div class="divider-line mt-3" />
										<b-row class="pt-20">
											<b-col>
												<h2>{{ FormMSG(25, 'Document list') }}</h2>
											</b-col>
										</b-row>
										<b-row class="pt-20">
											<b-col
												v-for="(childDoc, index) in documentData.childDocList"
												:key="index"
												sm="2"
												md="2"
												lg="2"
												xl="2"
												:class="{
													'width-50': $screen.width <= 576
												}"
												class="d-flex flex-column justify-content-center align-items-center"
											>
												<div
													class="doc-list-package size-2"
													@mouseenter="handleMouseEnter(childDoc)"
													@mouseleave="handleMouseLeave(childDoc)"
												>
													<div class="doc-list-package-action" :style="`height: ${childDoc.hoverAction ? '100%' : '0'}`">
														<div
															class="container-doc-list-package-action"
															:class="{
																'd-block': childDoc.hoverAction,
																'd-none': !childDoc.hoverAction
															}"
														>
															<div
																:class="{
																	'mb-70': childDoc.status < 2 && !isFrozen
																}"
															>
																<button
																	class="btn-transparent text-color-rhapsody-in-blue"
																	@click="handlePreviewDocument(childDoc)"
																>
																	<eye :size="40" :stroke-width="1.5" />
																</button>
															</div>
															<div v-if="childDoc.status < 2 && !isFrozen">
																<button
																	class="btn-transparent text-color-burning-tomato"
																	@click="handleClickRemoveChildDocument(childDoc)"
																>
																	<trash-2 :size="40" :stroke-width="1.5" />
																</button>
															</div>
														</div>
													</div>
													<span v-lazy-container="{ selector: 'img' }">
														<img
															:data-src="getImageName(childDoc.xid)"
															data-error="img/thumb-not-found.png"
															data-loading="img/brand/tenor.gif"
															alt="pict"
														/>
													</span>
												</div>
												<div class="fw-700 my-2">
													{{ childDoc.name }}
												</div>
											</b-col>
											<b-col
												v-if="!isFrozen"
												sm="2"
												md="2"
												lg="2"
												xl="2"
												:class="{
													'width-50': $screen.width <= 576
												}"
												class="d-flex justify-content-center"
											>
												<div class="add-doc-package">
													<div class="add-doc-package-action">
														<button class="btn-transparent text-color-white" @click="handleClickNewDocument">
															<plus-circle :size="75" :stroke-width="0.5" />
														</button>
													</div>
												</div>
											</b-col>
										</b-row>
									</div>
								</b-card-text>
							</b-card>
						</b-col>
					</b-row>
				</div>

				<modal-document-package
					:open="showModalDocument"
					:document-package="documentData"
					@modal-document-package:close="handleModalDocumentPackageClose"
					@modal-document-package:success="handleModalDocumentPackageSuccess"
				/>
			</div>
		</transition>
	</errorContainer>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import { PlusCircle, Eye, Trash2, ArrowLeft, Archive } from 'lucide-vue';
import { getFileExtension, getFileSrc } from '@/shared/helpers';
import ModalDocumentPackage from '@/modules/document-package/components/ModalDocumentPackage';
import { getDocument, updateDocument, addUpdDocValConfig, delDocumentChild, duplicateDocument } from '@/modules/document-package/cruds/document.crud';
import moment from 'moment';
import { store } from '@/store';
import globalMixin from '@/mixins/global.mixin';
import _ from 'lodash';
import ChoiceStepDocument from '@/modules/document-package/components/ChoiceStepDocument';

const axios = require('axios').create();

export default {
	name: 'DocumentPackageDetails',

	components: {
		PlusCircle,
		Eye,
		Trash2,
		ModalDocumentPackage,
		ArrowLeft,
		Archive,
		ChoiceStepDocument
	},

	mixins: [languageMessages, globalMixin],

	data() {
		return {
			documentId: 0,
			erreur: {},
			hoverAction: false,
			showModalDocument: false,
			isLoading: false,
			documentData: {
				id: 0,
				name: '',
				toEmail: false,
				toSms: false,
				toWhatsApp: false,
				body: false,
				createdAt: new Date(),
				archived: false,
				documentNextVersionId: 0,
				requireManagerApproval: false,
				publisher: {
					name: '',
					firstName: ''
				},
				childDocList: []
			},
			roleByStepToSend: []
		};
	},

	computed: {
		showCreateNewVersion() {
			// TODO: Wait field hasNextVersion
			return this.isFrozen && parseInt(this.documentData.documentNextVersionId) === 0;
		},
		isFrozen() {
			return parseInt(this.documentData.archived) >= 1;
		},
		showHasNextVersion() {
			return !this.showCreateNewVersion && parseInt(this.documentData.documentNextVersionId) > 0;
		}
	},

	watch: {
		step1: {
			handler(val) {
				if (!val.gateKeeper && !val.productionAccountant && !val.upm && !val.lineProducer && !val.studio) {
					this.resetStep(2);
				}
			},
			deep: true
		},
		step2: {
			handler(val) {
				if (!val.gateKeeper && !val.productionAccountant && !val.upm && !val.lineProducer && !val.studio) {
					this.resetStep(3);
				}
			},
			deep: true
		}
	},

	async mounted() {
		await this.getDocument();
	},

	methods: {
		handleChoiceStepDocumentChange(payload) {
			this.roleByStepToSend = payload;
		},
		async handleClickCreateNewVersion() {
			const result = await duplicateDocument(this.documentData.id, this.documentData.version + 1);

			const action = () => {
				this.$router.push({
					path: `/document-package/${result.id}`
				});
			};

			this.confirmModal(action, this.FormMSG(113, 'Do you want to stay on this page or go to the new version create ?'));
		},
		handlePreviewDocument(childDoc) {
			// documentForUser
			const list = this.documentData.childDocList.map((doc) => ({
				...doc,
				src: `${process.env.VUE_APP_GQL}/images/${doc.xid}`,
				thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + doc.xid,
				ext: getFileExtension(doc.fileName)
			}));

			const index = list.findIndex((l) => l.id === childDoc.id);

			this.$previewImages({
				images: list,
				focusIndex: index,
				options: {
					presentationMode: 'doc',
					showSignFileButton: false,
					showStatusFileTag: false,
					hideCommentButton: true,
					hideDisLikeButton: true,
					hideLikeDislike: true,
					hideCropButton: true,
					hideDeleteButton: true
				}
			});
		},

		async handleClickRemoveChildDocument(childDoc) {
			const action = async () => {
				try {
					await delDocumentChild(this.documentId, childDoc.id);
					this.documentData.childDocList = this.documentData.childDocList.filter((cDL) => cDL.xid !== childDoc.xid);
				} catch (error) {
					console.log(error);
				}
			};

			this.confirmModal(action, this.FormMSG(164, 'Are you sure ?'));
		},
		async handleClickSave() {
			this.isLoading = true;
			const documentId = this.documentData.id;
			delete this.documentData.id;

			let result = await updateDocument(documentId, this.documentData);

			await addUpdDocValConfig(this.roleByStepToSend);

			result.childDocList = this.documentData.childDocList;

			this.documentData = result;

			this.createToastForMobile(this.FormMSG(45, 'Success'), this.FormMSG(46, 'Updated successfully!'), '', 'success');
			this.isLoading = false;
		},
		handleClickCancel() {
			if (this.$route.query.viaDocuments) {
				this.$router.push({
					path: `/documents?tabs=activeTabs-1`
				});
			} else {
				this.$router.go(-1);
			}
		},
		async getDocument() {
			const documentId = parseInt(this.$route.params.id, 10);
			this.documentId = documentId;
			let doc = await getDocument(documentId, { useStatus: false });
			doc.childDocList.map((cDL) => {
				cDL.hoverAction = false;
			});
			this.documentData = doc;
		},
		handleModalDocumentPackageClose() {
			this.showModalDocument = false;
		},
		handleModalDocumentPackageSuccess(payload) {
			this.documentData.childDocList.push(payload);
			this.documentData.childDocList = this.documentData.childDocList.sort((a, b) => {
				if (a.name < b.name) {
					return -1;
				}
				if (a.name > b.name) {
					return 1;
				}
				return 0;
			});
			this.showModalDocument = false;
		},
		handleClickNewDocument() {
			this.showModalDocument = true;
		},
		handleMouseEnter(document) {
			document.hoverAction = true;
		},
		handleMouseLeave(document) {
			document.hoverAction = false;
		},
		getDateFormatted(value) {
			// return moment(value).format('DD/mm/yyyy hh:mm') <!-- 🤬 -->
			return moment(value).format('DD/MM/YYYY HH:mm');
		},
		getImageName(path) {
			const pathSplited = path.split('.')[0];
			return process.env.VUE_APP_GQL + '/imagethumb/' + pathSplited;
		},
		resetStep(step) {
			this[`step${step}`].gateKeeper = false;
			this[`step${step}`].productionAccountant = false;
			this[`step${step}`].upm = false;
			this[`step${step}`].lineProducer = false;
			this[`step${step}`].studio = false;
		}
	}
};
</script>
