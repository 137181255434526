var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur } },
    [
      _c("transition", { attrs: { name: "slide" } }, [
        _c(
          "div",
          {
            staticClass: "container-layout details-document-package",
            class: {
              "container-layout": _vm.$screen.width > 576,
              "container-mobile": _vm.$screen.width <= 576,
            },
          },
          [
            _c(
              "div",
              { staticClass: "form" },
              [
                _c(
                  "b-row",
                  [
                    _c("b-col", { attrs: { md: "12" } }, [
                      _c(
                        "h1",
                        {
                          class: [
                            `${!_vm.$screen.sm ? "main-page-title" : ""}`,
                            { "is-pwa": _vm.$isPwa() },
                          ],
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t\t\t" +
                              _vm._s(
                                _vm.FormMSG(17, "Document package detail")
                              ) +
                              "\n\t\t\t\t\t\t"
                          ),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
                _c(
                  "b-row",
                  { staticClass: "mt-3" },
                  [
                    _c(
                      "b-col",
                      { staticClass: "p-0" },
                      [
                        _c(
                          "b-card",
                          { attrs: { "no-body": "" } },
                          [
                            _c(
                              "b-row",
                              {
                                staticClass:
                                  "back-with-title cursor-pointer py-1",
                                class: { "mt-16": _vm.$isPwa() },
                              },
                              [
                                _c("b-col", { attrs: { cols: "6" } }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn-transparent",
                                      on: { click: _vm.handleClickCancel },
                                    },
                                    [
                                      _c("arrow-left", {
                                        staticClass: "icon",
                                        attrs: {
                                          color: "rgba(6, 38, 62, 0.65)",
                                          size: 22,
                                        },
                                      }),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            display: "inline-block",
                                            color: "#2e495d",
                                            "font-weight": "bolder",
                                            "font-size": "16px",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n\t\t\t\t\t\t\t\t\t\t\t" +
                                              _vm._s(_vm.FormMSG(163, "Back")) +
                                              "\n\t\t\t\t\t\t\t\t\t\t"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _c("b-card-text", [
                              _c(
                                "div",
                                { staticClass: "container-field mt-3" },
                                [
                                  _vm.showCreateNewVersion
                                    ? _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            [
                                              _c(
                                                "b-alert",
                                                {
                                                  attrs: {
                                                    variant: "success",
                                                    show: "",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-row",
                                                    [
                                                      _c(
                                                        "b-col",
                                                        {
                                                          attrs: { cols: "9" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  145,
                                                                  "You can no longer make a modification on this package. You must create another version."
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "b-col",
                                                        {
                                                          attrs: { cols: "3" },
                                                        },
                                                        [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              attrs: {
                                                                variant:
                                                                  "success",
                                                                block: "",
                                                                size: "sm",
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.handleClickCreateNewVersion,
                                                              },
                                                            },
                                                            [
                                                              _c("PlusCircle", {
                                                                attrs: {
                                                                  size: 16,
                                                                },
                                                              }),
                                                              _vm._v(
                                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                                  _vm._s(
                                                                    _vm.FormMSG(
                                                                      146,
                                                                      "Create new version"
                                                                    )
                                                                  ) +
                                                                  "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm.showHasNextVersion
                                    ? _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            [
                                              _c(
                                                "b-alert",
                                                {
                                                  attrs: {
                                                    variant: "warning",
                                                    show: "",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-row",
                                                    [
                                                      _c(
                                                        "b-col",
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  196,
                                                                  "This document already has a version created,"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                          _c(
                                                            "router-link",
                                                            {
                                                              staticClass:
                                                                "text-color-aqua-velvet",
                                                              attrs: {
                                                                to: `/document-package/${_vm.documentData.documentNextVersionId}`,
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.FormMSG(
                                                                    197,
                                                                    "click here to see it."
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c(
                                    "b-row",
                                    [
                                      _c("b-col", [
                                        _c("h2", [
                                          _vm._v(
                                            _vm._s(_vm.FormMSG(18, "Settings"))
                                          ),
                                        ]),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "3" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  111,
                                                  "Version"
                                                ),
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  value: `V${_vm.documentData.version}`,
                                                  disabled: "",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: "6",
                                            lg: "6",
                                            xl: "6",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: _vm.FormMSG(112, "Name"),
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  placeholder: _vm.FormMSG(
                                                    19,
                                                    "Ex: Technician onboarding package"
                                                  ),
                                                  disabled: _vm.isFrozen,
                                                },
                                                model: {
                                                  value: _vm.documentData.name,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.documentData,
                                                      "name",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "documentData.name",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: "6",
                                            lg: "6",
                                            xl: "6",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  20,
                                                  "Sharing options"
                                                ),
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  attrs: {
                                                    id: "chk_details_doc_package",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-form-checkbox",
                                                    {
                                                      attrs: {
                                                        id: "sendEmail",
                                                        disabled: _vm.isFrozen,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.documentData
                                                            .toEmail,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.documentData,
                                                            "toEmail",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "documentData.toEmail",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              21,
                                                              "Send by email"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-form-checkbox",
                                                    {
                                                      attrs: {
                                                        id: "sendSMS",
                                                        disabled: _vm.isFrozen,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.documentData
                                                            .toSms,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.documentData,
                                                            "toSms",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "documentData.toSms",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              22,
                                                              "Send by SMS"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-form-checkbox",
                                                    {
                                                      attrs: {
                                                        id: "sendWatsApp",
                                                        disabled: _vm.isFrozen,
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.documentData
                                                            .toWhatsApp,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.documentData,
                                                            "toWhatsApp",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "documentData.toWhatsApp",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.FormMSG(
                                                              23,
                                                              "Send by WhatsApp"
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: "3",
                                            lg: "3",
                                            xl: "3",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  51,
                                                  "Created by"
                                                ),
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  value: `${_vm.documentData.publisher.name} ${_vm.documentData.publisher.firstName}`,
                                                  disabled: "",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: "3",
                                            lg: "3",
                                            xl: "3",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  53,
                                                  "Creation date"
                                                ),
                                              },
                                            },
                                            [
                                              _c("b-form-input", {
                                                attrs: {
                                                  value: _vm.getDateFormatted(
                                                    _vm.documentData.createdAt
                                                  ),
                                                  disabled: "",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          attrs: {
                                            sm: "12",
                                            md: "6",
                                            lg: "6",
                                            xl: "6",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: _vm.FormMSG(
                                                  65,
                                                  "Message"
                                                ),
                                              },
                                            },
                                            [
                                              _c("b-form-textarea", {
                                                attrs: {
                                                  id: "textarea",
                                                  placeholder: _vm.FormMSG(
                                                    24,
                                                    "Enter message here..."
                                                  ),
                                                  "no-resize": "",
                                                  state:
                                                    _vm.documentData.body &&
                                                    _vm.documentData.body
                                                      .length < 4000,
                                                  rows: "3",
                                                  disabled: _vm.isFrozen,
                                                },
                                                model: {
                                                  value: _vm.documentData.body,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.documentData,
                                                      "body",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "documentData.body",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c("div", {
                                    staticClass: "divider-line mt-3",
                                  }),
                                  _c(
                                    "b-row",
                                    { staticClass: "mt-20 mb-20" },
                                    [
                                      _c("b-col", [
                                        _c("h2", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.FormMSG(55, "Approval steps")
                                            )
                                          ),
                                        ]),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        [
                                          _c(
                                            "b-form-checkbox",
                                            {
                                              staticClass: "pj-cb pb-1",
                                              attrs: {
                                                size: "lg",
                                                id: "requireSignManager",
                                                disabled: _vm.isFrozen,
                                              },
                                              model: {
                                                value:
                                                  _vm.documentData
                                                    .requireManagerApproval,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.documentData,
                                                    "requireManagerApproval",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "documentData.requireManagerApproval",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      169,
                                                      "Require approval / manager signature"
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-collapse",
                                    {
                                      attrs: {
                                        visible:
                                          _vm.documentData
                                            .requireManagerApproval,
                                      },
                                    },
                                    [
                                      _c("choice-step-document", {
                                        attrs: {
                                          "is-disabled": _vm.isFrozen,
                                          document: _vm.documentData,
                                        },
                                        on: {
                                          "choice-step-document:change":
                                            _vm.handleChoiceStepDocumentChange,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  !_vm.isFrozen
                                    ? _c(
                                        "div",
                                        { staticClass: "mt-5" },
                                        [
                                          _vm.$screen.width > 576
                                            ? _c(
                                                "b-row",
                                                { staticClass: "mt-25" },
                                                [
                                                  _c(
                                                    "b-col",
                                                    {
                                                      attrs: {
                                                        cols: "4",
                                                        offset: "8",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          attrs: {
                                                            variant: "primary",
                                                            block: "",
                                                            disabled:
                                                              _vm.isLoading,
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.handleClickSave,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  27,
                                                                  "Save changes"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _vm.$screen.width <= 576
                                            ? _c(
                                                "b-row",
                                                { staticClass: "footer-fixed" },
                                                [
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass: "mb-3",
                                                      attrs: { cols: "12" },
                                                    },
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          attrs: {
                                                            variant: "primary",
                                                            block: "",
                                                            disabled:
                                                              _vm.isLoading,
                                                          },
                                                          on: {
                                                            click:
                                                              _vm.handleClickSave,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n\t\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  27,
                                                                  "Save changes"
                                                                )
                                                              ) +
                                                              "\n\t\t\t\t\t\t\t\t\t\t\t\t"
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _c("div", {
                                    staticClass: "divider-line mt-3",
                                  }),
                                  _c(
                                    "b-row",
                                    { staticClass: "pt-20" },
                                    [
                                      _c("b-col", [
                                        _c("h2", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.FormMSG(25, "Document list")
                                            )
                                          ),
                                        ]),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "pt-20" },
                                    [
                                      _vm._l(
                                        _vm.documentData.childDocList,
                                        function (childDoc, index) {
                                          return _c(
                                            "b-col",
                                            {
                                              key: index,
                                              staticClass:
                                                "d-flex flex-column justify-content-center align-items-center",
                                              class: {
                                                "width-50":
                                                  _vm.$screen.width <= 576,
                                              },
                                              attrs: {
                                                sm: "2",
                                                md: "2",
                                                lg: "2",
                                                xl: "2",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "doc-list-package size-2",
                                                  on: {
                                                    mouseenter: function (
                                                      $event
                                                    ) {
                                                      return _vm.handleMouseEnter(
                                                        childDoc
                                                      )
                                                    },
                                                    mouseleave: function (
                                                      $event
                                                    ) {
                                                      return _vm.handleMouseLeave(
                                                        childDoc
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "doc-list-package-action",
                                                      style: `height: ${
                                                        childDoc.hoverAction
                                                          ? "100%"
                                                          : "0"
                                                      }`,
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "container-doc-list-package-action",
                                                          class: {
                                                            "d-block":
                                                              childDoc.hoverAction,
                                                            "d-none":
                                                              !childDoc.hoverAction,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              class: {
                                                                "mb-70":
                                                                  childDoc.status <
                                                                    2 &&
                                                                  !_vm.isFrozen,
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "button",
                                                                {
                                                                  staticClass:
                                                                    "btn-transparent text-color-rhapsody-in-blue",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.handlePreviewDocument(
                                                                          childDoc
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c("eye", {
                                                                    attrs: {
                                                                      size: 40,
                                                                      "stroke-width": 1.5,
                                                                    },
                                                                  }),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          ),
                                                          childDoc.status < 2 &&
                                                          !_vm.isFrozen
                                                            ? _c("div", [
                                                                _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn-transparent text-color-burning-tomato",
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.handleClickRemoveChildDocument(
                                                                            childDoc
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "trash-2",
                                                                      {
                                                                        attrs: {
                                                                          size: 40,
                                                                          "stroke-width": 1.5,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ])
                                                            : _vm._e(),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "lazy-container",
                                                          rawName:
                                                            "v-lazy-container",
                                                          value: {
                                                            selector: "img",
                                                          },
                                                          expression:
                                                            "{ selector: 'img' }",
                                                        },
                                                      ],
                                                    },
                                                    [
                                                      _c("img", {
                                                        attrs: {
                                                          "data-src":
                                                            _vm.getImageName(
                                                              childDoc.xid
                                                            ),
                                                          "data-error":
                                                            "img/thumb-not-found.png",
                                                          "data-loading":
                                                            "img/brand/tenor.gif",
                                                          alt: "pict",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "fw-700 my-2" },
                                                [
                                                  _vm._v(
                                                    "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                      _vm._s(childDoc.name) +
                                                      "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      !_vm.isFrozen
                                        ? _c(
                                            "b-col",
                                            {
                                              staticClass:
                                                "d-flex justify-content-center",
                                              class: {
                                                "width-50":
                                                  _vm.$screen.width <= 576,
                                              },
                                              attrs: {
                                                sm: "2",
                                                md: "2",
                                                lg: "2",
                                                xl: "2",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "add-doc-package",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "add-doc-package-action",
                                                    },
                                                    [
                                                      _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn-transparent text-color-white",
                                                          on: {
                                                            click:
                                                              _vm.handleClickNewDocument,
                                                          },
                                                        },
                                                        [
                                                          _c("plus-circle", {
                                                            attrs: {
                                                              size: 75,
                                                              "stroke-width": 0.5,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("modal-document-package", {
              attrs: {
                open: _vm.showModalDocument,
                "document-package": _vm.documentData,
              },
              on: {
                "modal-document-package:close":
                  _vm.handleModalDocumentPackageClose,
                "modal-document-package:success":
                  _vm.handleModalDocumentPackageSuccess,
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }